import { CardNETCustomer } from '@/models/cardnet-customer';
import { Collection } from '@/models/collection';
import { Resource } from '@/models/resource';
import { UpdateUserDTO, User } from '@/models/user';
import { UserFavoriteProduct } from '@/models/user-favorite-product';
import { Wallet } from '@/models/wallet';
import { AuthenticationService } from '@absdepot/data/authentication';
import { HttpClient, HttpParams } from '@angular/common/http';
import { computed, effect, inject, Injectable } from '@angular/core';
import { rxResource } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular/standalone';
import { firstValueFrom, map, switchMap, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { pruneUndefined } from '../../shared/utils/prune-undefined';
import { Logger } from './logger.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly #logger = inject(Logger);
  readonly #router = inject(Router);

  private readonly auth = inject(AuthenticationService);
  private readonly http = inject(HttpClient);
  private readonly toastCtrl = inject(ToastController);

  private readonly reloader = computed(() => this.auth.token() ?? undefined);

  readonly resource = rxResource({
    request: this.reloader,
    loader: () => this.getUserData(),
  });

  readonly walletResource = rxResource({
    request: this.resource.value,
    loader: () => this.getUserWallet(),
  });

  readonly paymentDataResource = rxResource({
    request: this.resource.value,
    loader: () => this.getUserPaymentData(),
  });

  constructor() {
    effect(() => this.#logger.setUser(this.resource.value()));
  }

  private getUserPaymentData() {
    const url = `${environment.apiUrl}/users/me/payment-data`;
    return this.http.get<CardNETCustomer>(url);
  }

  private getUserWallet() {
    const url = `${environment.apiUrl}/users/me/wallet`;
    return this.http.get<Resource<Wallet>>(url);
  }

  getUserData() {
    const url = `${environment.apiUrl}/users/me`;
    return this.http.get<Resource<User>>(url).pipe(map((r) => r.data));
  }

  update(dto: UpdateUserDTO): Promise<User> {
    const url = `${environment.apiUrl}/users/me`;
    const request = this.http
      .put<Resource<User>>(url, pruneUndefined(dto))
      .pipe(
        map((res) => res.data),
        tap(() => this.resource.reload()),
      );
    return firstValueFrom(request);
  }

  requestAccountDeletion() {
    this.http
      .delete<{ message: string }>(`${environment.apiUrl}/users/me`)
      .pipe(
        switchMap(async ({ message }) => {
          const toast = await this.toastCtrl.create({
            message,
            color: 'danger',
            duration: 3000,
            position: 'top',
          });
          await toast.present();
        }),
        switchMap(async () => this.auth.logout()),
        tap(() => this.#router.navigate(['/'])),
      )
      .subscribe();
  }

  getFavoriteProducts(page: number) {
    const url = `${environment.apiUrl}/users/me/favorite-products`;
    const params = new HttpParams().appendAll({ page });
    return this.http.get<Collection<UserFavoriteProduct>>(url, { params }).pipe(
      map(({ meta, data }) => ({
        meta,
        products: data.map((data) => data.product),
      })),
    );
  }
}
